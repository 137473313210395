* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}
html, body {
  height: 100%;
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 1.235;
}
img {
  max-width: 100%;
  display: block;
}
#root {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background:url('./assets/bg.png') no-repeat bottom center;
  background-size: cover;
}
#root.changeBg {
  background-color: transparent;
}
#root.changeBg:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255,255,255,0.5);
}
.screen {
  width: 100%;
  height: 100%;
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.screen-login {
  display: grid;
  grid-template-rows: 1fr repeat(1, auto) 1fr;
  grid-row-gap: 5px;
  justify-items: center;
  padding: 1rem;
}
.screen-selfie {
  display: grid;
  grid-template-rows: 1fr repeat(1, auto) 1fr;
  grid-row-gap: 5px;
  justify-items: center;
  padding: 1rem;
  overflow-y: auto;
 }
.ciplalogo {
  width: 70px;
  margin-bottom: auto;
  grid-row-start: 1;
  justify-self: flex-end;
}
.formUnit {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.canvasUnit {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.oasislogoSmall {
  max-width: 240px;
  align-self: center;
}
.btn {
  background: #3055a5;
  background-image: linear-gradient(to bottom, #567dd1, #3055a5);
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  box-shadow: -4px 4px 5px -3px rgba(0,0,0,0.5);
  color: #fff;
  font-size: 1rem;
  font-family: inherit;
  padding: 0.75rem 2rem;
  font-weight: bold;
  border: 0;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
}
.btn2 {
  background: #3055a5;
  background-image: linear-gradient(to bottom, #567dd1, #3055a5);
  border-radius: 0.25rem;
  border: 1px solid #ddd;
  box-shadow: -4px 4px 5px -3px rgba(0,0,0,0.5);
  color: #fff;
  font-size: 0.875rem;
  font-family: inherit;
  padding: 0.325rem 1rem;
  font-weight: bold;
  border: 0;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
}
.form-layout {
  align-self: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1rem;
  border-radius: 0.5rem;
  width: 100%;
  gap: 1rem;
  margin-top: 1rem;
  box-shadow: 4px 4px 10px -6px rgba(0,0,0,0.5);
  position: relative;
}
.form-group {
  position: relative;
  width: 100%;
}
.form-control {
  width: 100%;
  display: block;
  padding: 0.75rem 1rem;
  font-family: inherit;
  font-size: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ddd;
  box-shadow: inset 2px 2px 5px rgba(0,0,0,0.1);
}
.required {
  position: relative;
}
.required:after {
  content: '*';
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: red;
  font-weight: bold;
  font-family: inherit;
}
.mandatoryText {
  font-size: 0.65rem;
  color: #a1a1a1;
  position: absolute;
  bottom: 0.5rem;
  right: 0.75rem;
}
.popOvarlay {
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.photoFrame {
  width: 200px;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
}
.photoFrame img {
  width: 100%;
}
.changePhoto {
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  color: #fff;
  text-align: center;
  width: 100%;
  padding: 0.375rem 1rem;
}
.innerFrame {
  border: 4px solid #3055a5;
  border-radius: 4px;
  box-shadow: 4px 4px 12px -4px rgba(0,0,0,0.8);
}
.fullImg {
  width: 320px;
  position: relative;
}
.userImg {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
  background: #3055a5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 140px;
}
.userImg img {
  width: 85px;
  height: 85px;
  border-radius: 100%;
}
.userContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #3055a5;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 250px;
}
.userName {
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.userPlace {
  text-transform: capitalize;
}
.loadingScreen {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  background: rgba(0,0,0,0.75);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  gap: 1rem;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
